import React from 'react';
import NoMatch from '../components/NoMatch';
import {Route, Switch} from 'react-router-dom';
import ErrorPage from "../components/ErrorPage";
import Donate from "../components/Donate";
import OrganizationInput from "../components/OrganizationInput";

export const Routes = () => {
  return (
      <Switch>
        <Route exact path="/" component={OrganizationInput}/>
        <Route exact path="/:organizationPublicId/:deviceLockedTo" component={Donate}/>
        <Route exact path="/:organizationPublicId" component={Donate}/>
        <Route path='/error' component={ErrorPage} />
        <Route path='*' component={NoMatch} />
      </Switch>
  );
};

export default Routes;