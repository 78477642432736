
class Logger {
  constructor() {
    this.url = "https://logs-01.loggly.com/inputs/2575d25d-c5b7-46dd-8038-82b3e4368101/tag/goodbricks-terminal/";
  }

  log(logData) {
    return this.doPost(this.url, logData);
  }

  async doPost(url, body) {
    let response = await fetch(url, {
      method: "post",
      body: JSON.stringify(body)
    });

    if (response.ok) {
      return response.json();
    } else {
      let text = await response.text();
      throw new Error("Loggly Request Failed: " + text);
    }
  }

}

export default Logger;
