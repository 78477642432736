import React from 'react';
import * as PubSub from "pubsub-js";

class WindowFunctionLoader extends React.Component {

  componentWillMount() {
    window.webViewEventHandler = function(webViewEvent) {
      console.log("### webViewEvent ###", webViewEvent)
      // const parsedWebViewEvent = JSON.parse(webViewEvent)
      PubSub.publish(webViewEvent.topic, webViewEvent.message);
    }
  }

  render(){
    return (
        <div/>
    );
  }
};

export default WindowFunctionLoader;
