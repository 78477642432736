import React from 'react';

const ErrorPage = () => {
  return (
      <div>
        <h3>Hmm... Something went wrong... Please try again.</h3>
      </div>
  );
};

export default ErrorPage;
