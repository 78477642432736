import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss'
import Layout from "./components/layout/Layout";

ReactDOM.render(
    <Layout className="page-container"/>,
    document.getElementById('root'),
);

serviceWorker.unregister();
