const constants = {
  USER_ACTION_MODAL_SHOW_PROCESSING: "showProcessing",
  USER_ACTION_MODAL_SHOW_CARD_INPUT_OPTIONS_TRANSACTION: "showCardInputOptionsTransaction",
  USER_ACTION_MODAL_SHOW_CARD_INPUT_OPTIONS_SUBSCRIBE: "showCardInputOptionsSubscribe",
  USER_ACTION_MODAL_SHOW_REMOVE_CARD: "showCardInputOptions",
  USER_ACTION_MODAL_SHOW_CUSTOMER_DETAILS: "showCustomerDetails",
  USER_ACTION_MODAL_SHOW_SUBSCRIBE_DETAILS: "showSubscribeDetails",
  USER_ACTION_MODAL_SHOW_CONFIRMATION: "showConfirmation",
  USER_ACTION_MODAL_SHOW_ERROR: "showError",
};

export default constants;