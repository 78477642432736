import React from 'react';
import Routes from '../../config/routes'; // where we are going to specify our routes
import Header from './Header';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import Footer from "./Footer";
import WindowFunctionLoader from "./WindowFunctionLoader";

class Layout extends React.Component {

  render() {
    return (
         <BrowserRouter>
            <div>
              <Header/>
              <WindowFunctionLoader/>
              <div id='content' className='container u-full-width'>
                <Routes/> {/* This is where components will be rendered  */}
              </div>
              <Footer/>
            </div>
          </BrowserRouter>
    );
  }

}

export default Layout;