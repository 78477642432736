import React from 'react';
import {Button, Drawer, Navbar, NavbarDivider, NavbarGroup, NavbarHeading} from "@blueprintjs/core";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import {Position} from "@blueprintjs/core/lib/cjs/common/position";
import {Alignment} from "@blueprintjs/core/lib/cjs";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false
    }
  }

  handleCloseDrawer = () => {
    this.setState({
      isDrawerOpen: false
    })
  }

  handleOpenDrawer = () => {
    this.setState({
      isDrawerOpen: true
    })
  }

  render()
  {
    return (
        <footer>
          <Drawer
              title="Goodbricks - Developed by Caretapps"
              onClose={this.handleCloseDrawer}
              isOpen={this.state.isDrawerOpen}
              position={Position.BOTTOM}>
            <div className={Classes.DRAWER_BODY}>
              <div className={Classes.DIALOG_BODY}>
                <h5 className='bp3-heading text-primary'>What is Goodbricks</h5>
                <p className='bp3-running-text'>
                  Goodbricks is a cloud-based donor management application to accept donations online, through kiosk, text, etc. , engage donors, manage memberships, plan events and much more. For more information or questions, email <span
                    className="text-primary"> info@goodbricksapp.com</span> or call <span className="text-primary">(858) 345-7662</span>
                </p>
                <p className='py-2'/>
                <h5 className='bp3-heading text-primary'>Who is Caretapps</h5>
                <p className='bp3-running-text'>
                  Caretapps is a company that builds application for non-profits and small businesses to stay up to date and relevant with modern day technology.
                  For more information or questions, email <span className="text-primary"> info@caretapps.com</span> or call <span className="text-primary">(858) 345-7662</span>
                </p>
              </div>
            </div>
          </Drawer>

          <Navbar className="bp3-dark" style={{display: "flex"}} >
            <NavbarGroup style={{marginLeft: "auto", marginRight:"auto"}}>
              <p className='bp3-text-muted'>Powered by <a onClick={this.handleOpenDrawer} >Goodbricks</a></p>
            </NavbarGroup>
          </Navbar>

        </footer>

    );
  }
}

export default Footer;
