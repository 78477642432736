import React from 'react';
import { withRouter } from "react-router";
import TerminalRestClient from "../terminalRestClient";
import defaults from "../../config/defaults";
import {Button, Navbar, NavbarGroup, NavbarHeading} from "@blueprintjs/core";
import {Alignment} from "@blueprintjs/core/lib/cjs/common/alignment";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.client = new TerminalRestClient(defaults.remoteBaseUrl);
    this.state = {
      organizationName : this.props.match.params.organizationPublicId
    }
  }

  async componentWillMount() {
    if(this.props.location.pathname){
      const organizationResponse = await this.client.retrieveOrganization(this.props.location.pathname.split('/')[1]);
      if(organizationResponse){
        this.setState({
          organizationName: organizationResponse.name
        })
      }
    }
  }

  reloadWindow = () => {
    window.location.reload();
  }

  render(){
    return (
        <header>
          <Navbar className="bp3-dark" style={{display: "flex"}} >
            <NavbarGroup style={{marginLeft: "auto", marginRight:"auto"}}>
              <NavbarHeading>{this.state.organizationName}</NavbarHeading>
            </NavbarGroup>
            <NavbarGroup align={Alignment.RIGHT}>
              <Button minimal icon="refresh" onClick={this.reloadWindow}/>
            </NavbarGroup>
          </Navbar>
        </header>
    );
  }
};

export default withRouter(Header);
